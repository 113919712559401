import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "utils/Seo"
import {Container} from "reactstrap";
import styled from "styled-components";
import Footer from "../components/Layout/Footer";
import {Link} from "gatsby";

const Wrapper = styled.div`
    position: relative;
`

const CookiePage = () => {
    return (
        <Layout>
            <SEO title="Home" />
            <Wrapper>
                <Container fluid={true}>
                    <Container className="position-relative">
                        <h1>Cookie Policy</h1>
                        <p>This policy was last updated on Monday 19<sup>th</sup> July 2021. Any future changes to our policy will be reflected here.</p>
                        <h2>Granting us permission to use cookies</h2>
                        <p>When you first visit the website you will be asked to consent for us to use cookies. You can allow all cookies or refuse some or all. If you change your mind you can click on the Privacy Settings link at the bottom of the website pages to adjust your selection.</p>
                        <h2>Cookies and how they benefit you:</h2>
                        <p>Our website uses cookies and other tracking technologies, as almost all websites do, to help provide you with the best experience we can. Cookies are small text files that are placed on your computer or mobile phone when you browse websites. Other tracking technologies (referred to together from this point forward as &ldquo;cookies&rdquo;, unless otherwise stated), include HTTP cookies, HTML5 and Flash local storage/flash cookies, web beacons/GIFs, embedded scripts, ETags/cache browsers, and software development kits.</p>
                        <p>To read our Privacy Policy please click here.</p>
                        <p><em>The Mousetrap</em>&nbsp;uses cookies for three main purposes:</p>
                        <ul>
                            <li>To ensure the website works, in particular for online booking</li>
                            <li>To monitor website performance and help us make improvements in the future</li>
                            <li>To tailor our marketing, and use tools such as Google AdWords to communicate more effectively through web advertising.</li>
                        </ul>
                        <p>Our cookies help us:</p>
                        <ul>
                            <li>Make our website work as you&rsquo;d expect</li>
                            <li>Save you having to login every time you visit the site</li>
                            <li>Remember your settings during and between visits</li>
                            <li>Improve the speed/security of the site</li>
                            <li>Allow you to share pages with social networks like Facebook</li>
                            <li>Continuously improve our website for you</li>
                            <li>Provide you with advertising relating to this and other shows</li>
                        </ul>
                        <p>We do not use cookies to:</p>
                        <ul>
                            <li>Collect any sensitive information</li>
                        </ul>
                        <p>You can learn more about the key cookies we use below:</p>
                        <table width="663">
                            <tbody>
                            <tr>
                                <td width="331">
                                    <p>Cookie</p>
                                </td>
                                <td width="332">
                                    <p>Purpose</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="331">
                                    <p>Google Analytics</p>
                                </td>
                                <td width="332">
                                    <p>This monitors how people use our site &ndash; for example the browser they use and the pages they visit. It&rsquo;s used to assess our website&rsquo;s performance and to help us plan improvements.</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="331">&nbsp;</td>
                                <td width="332">
                                    <p>You may notice that sometimes after visiting our site you see increased numbers of adverts from us. This is because we pay for these adverts, and the technology to do this is made possible by this cookie. We use these adverts to inform you about this or other shows.&nbsp;</p>
                                    <p>&nbsp;</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="331">
                                    <p>Facebook / Instagram / Twitter / Snapchat</p>
                                </td>
                                <td width="332">
                                    <p>This communicates with Facebook / Instagram / Snapchat / Twitter activity to our website.</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="331">
                                    <p>Session</p>
                                </td>
                                <td width="332">
                                    <p>This cookie is a transient cookie (it is erased when you close the browser). It acts as a small amount of memory to remember what you&rsquo;ve done on past pages.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <p>If you have any questions about cookies, please contact the Data Protection Manager by email to&nbsp;<a href="mailto:enquiries@stmartinstheatre.co.uk"><strong>enquiries@stmartinstheatre.co.uk</strong></a>&nbsp;or by post to: Mousetrap Theatre Productions, 1st Floor, 11 Garrick Street, London WC2E 9AR.&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>
                            <Link to="/">Back to Home</Link>
                        </p>
                    </Container>
                </Container>
            </Wrapper>
            <Footer/>
        </Layout>
    )
}

export default CookiePage